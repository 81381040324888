import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import axios from 'axios'

Vue.use(Vuex)

const session = {
  namespaced: true,

  state: () => ({
    session: {
      access: '',
      refresh: '',
      user: ''
    },
    localStorage: false
  }),

  mutations: {
    SET_SESSION_DATA(state, data) {
      const session = {
        access: data.access? data.access : data.access,
        refresh: data.refresh,
        user: data.user,
      }
      state.session = session
      
      if (!data.alreadyLoaded) {
        state.localStorage = data.remember
        if (data.remember) {
          localStorage.setItem('session', JSON.stringify(session))
        } else {
          sessionStorage.setItem('session', JSON.stringify(session))
        }
        // console.log(this.commit)
        // dispatch('session/setSelectedCompany', data.selected_company)
       
      }
    },

    CLEAR_SESSION_DATA(state) {
      // state.user = null
      state.localStorage = false,

      sessionStorage.removeItem('session')
      localStorage.removeItem('session')
    },

    REFRESH_TKN(state, data) {
      state.session.access = data.access
      localStorage.setItem('session', JSON.stringify(state.session))
    },
   
  },

  actions: {
    setSessionData({ commit }, session_data) {
      commit('SET_SESSION_DATA', session_data)
    },
    logoutUser({ commit }) {
      commit('CLEAR_SESSION_DATA')
    },
  },

  getters: {
    logged: (state) => () => { return !! state.session },
    get_token: (state) => () =>{ return state.session?.access },
    refresh: (state) => () =>{ return state.session?.refresh },
    userData: (state) => () =>{ return state.session?.user },
    getUserRole: (state) => () =>{ return state.session?.user?.role },
  },
}


export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
    session: session,
  },
})


// axios
import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    // 'Content-Type': 'multipart/form-data'
  }
})

axiosIns.interceptors.request.use(
  config => {
        // Do something before request is sent
    const sessionData = localStorage.getItem('session') || sessionStorage.getItem('session')
    const parsedData = JSON.parse(sessionData)
    const accessToken = parsedData?.access

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`
    // console.log(parsedData)

    return config
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(response => {
  return response;
}, error => {
  if (error?.response?.data?.code === "token_not_valid") {
    console.log('token vencido')

    sessionStorage.removeItem('session')
    localStorage.removeItem('session')

    window.location.replace('login')
  }
  return Promise.reject(error);
});

//logIn
axiosIns.login = form => {
  return axiosIns.post(`account/login`, form)
    .then(response => response.data)
}

//reset forgotten password
axiosIns.resetPassword = form => axiosIns.post(`account/password/reset`, form, {timeout: 7000})
  .then(response => response)

//validate token from password recovery
axiosIns.validateResetPassToken = form => axiosIns.post(`account/password/validate`, form)
  .then(response => response)

axiosIns.changeUserPassword = form => axiosIns.post(`account/password/confirm`, form)
  .then(response => response)

//logged-UserData
axiosIns.getUserDataForProfile = () => axiosIns.get(`api/get-user-info`)
  .then(response => response.data)

// Update Profile
axiosIns.updateUserDataForProfile = form => axiosIns.put(`app/get_user_info`, form) /* CORREGIR */
  .then(response => response.data)

axiosIns.changeUserPasswordProfile = form => axiosIns.patch(`account/password/change`, form)
  .then(response => response.data)

//create User
axiosIns.createUser = form => axiosIns.post(`account/register`, form)
  .then(response => response.data)

//get Users
axiosIns.getUsersList = (data) => {
  let url =
    data.page
      ? `api/users/?page=${data.page}&page_size=${data.page_size}`
      : `api/users/?paginated=false`

  url += data.search?.length? `&search=${data.search}` : ''
  url += data.summary ? `&summary=${data.summary}` : ''
  url += data.only_users ? `&only_users=${data.only_users}` : ''
  url += data.role >=0  ? `&role=${data.role}` : ''

  return axiosIns.get(url)
    .then(response => response.data)
}

axiosIns.getUsersSummary = (summary) => axiosIns.get(`api/users/?summary=${summary}`)
  .then(response => response.data)

//get 1 User
axiosIns.getUser = id => axiosIns.get(`api/users/${id}/`)
  .then(response => response)


//update User
axiosIns.updateUser = (id,form) => axiosIns.patch(`api/users/${id}/`, form)
  .then(response => response.data)

//delete
axiosIns.deleteUser = id => axiosIns.delete(`api/users/${id}/`)
  .then(response => response)


 /* VETERINARY EPS */
axiosIns.createVeterinary = form => axiosIns.post(`api/veterinary-clinics/`, form)
  .then(response => response.data)

  //get only id,name of users with specific role
axiosIns.getAsociatedUsers = (summary, role) => axiosIns.get(`api/users/?summary=${summary}&role=${role}`)
  .then(response => response.data)

axiosIns.getVeterinaryList = (data) => {
  let url = `api/veterinary-clinics/?page=${data.page}&page_size=${data.page_size}`
  url += data.search?.length? `&search=${data.search}` : ''
  url += data.summary ? `&summary=${data.summary}` : ''

  return axiosIns.get(url)
    .then(response => response.data)
}

//get only id,name
axiosIns.getVeterinarysSummary = summary => axiosIns.get(`api/veterinary-clinics/?summary=${summary}`)
  .then(response => response.data)

axiosIns.getVeterinary = id => axiosIns.get(`api/veterinary-clinics/${id}/`)
  .then(response => response)

axiosIns.updateVeterinary = (id,form) => axiosIns.put(`api/veterinary-clinics/${id}/`, form)
  .then(response => response)

axiosIns.deleteVeterinary = id => axiosIns.delete(`api/veterinary-clinics/${id}/`)
  .then(response => response)


 /* TUTORS EPS */
axiosIns.createTutor = form => axiosIns.post(`api/tutors/`, form)
  .then(response => response)

axiosIns.getTutorsList = (data) => {
  let url = `api/tutors/?page=${data.page}&page_size=${data.page_size}`
  url += data.search?.length? `&search=${data.search}` : ''
  url += data.summary ? `&summary=${data.summary}` : ''
  url += data.patient ? `&patient=${data.patient}` : ''

  return axiosIns.get(url)
    .then(response => response.data)
}

//get only id,name of tutors
axiosIns.getTutorsSummary = summary=> axiosIns.get(`api/tutors/?summary=${summary}`)
.then(response => response.data)

axiosIns.deleteTutor = id => axiosIns.delete(`api/tutors/${id}/`)
  .then(response => response)

axiosIns.updateTutor = (id,form) => axiosIns.patch(`api/tutors/${id}/`, form)
  .then(response => response)

axiosIns.getTutor = id => axiosIns.get(`api/tutors/${id}/`)
  .then(response => response)

 /* PATIENTS EPS */
 axiosIns.createPatient = form => axiosIns.post(`api/patients/`, form, {headers: {'Content-Type': 'multipart/form-data'}})
 .then(response => response.data)

axiosIns.getPatientsList = (data) => {
  let url = `api/patients/?page=${data.page}&page_size=${data.page_size}`
  url += data.search?.length ? `&search=${data.search}` : ''
  url += data.summary ? `&summary=${data.summary}` : ''
  url += data.tutor? `&tutor=${data.tutor}` : ''
  url += data.userdata ? `&userdata=${data.userdata}` : ''

  return axiosIns.get(url)
    .then(response => response.data)
}
   //get only id,name of patients
axiosIns.getPatientsSummary = summary=> axiosIns.get(`api/patients/?summary=${summary}`)
.then(response => response.data)

axiosIns.deletePatient = id => axiosIns.delete(`api/patients/${id}/`)
 .then(response => response)

axiosIns.updatePatient = (id,form) => axiosIns.patch(`api/patients/${id}/`, form)
 .then(response => response.data)

axiosIns.getPatient = id => axiosIns.get(`api/patients/${id}/`)
 .then(response => response)

 /*  FILES EPS */
axiosIns.createFiles = (id,form) => axiosIns.post(`api/patients/${id}/files`, form, {headers: {'Content-Type': 'multipart/form-data'}})
 .then(response => response.data)

axiosIns.getPatientFiles = id => axiosIns.get(`api/patients/${id}/files`)
 .then(response => response)

axiosIns.deletePatientFiles = (id, form) => axiosIns.delete(`api/patients/${id}/files`, {data: form})
 .then(response => response)


 /*  DETERMINATIONS EPS */

//Get List
 axiosIns.getDeterminationsList = (data) => {
  let url =
    data.page
      ? `api/determinations/?page=${data.page}&page_size=${data.page_size}`
      : `api/determinations/?paginated=false`

  url += data.page_size >0 ? `&page_size=${data.page_size}` : ''
  url += data.page >0 ? `&page=${data.page}` : ''
  url += data.search?.length ? `&search=${data.search}` : ''
  url += data.summary ? `&summary=${data.summary}` : ''
  url += data.simulator ? `&simulator=true` : ''
  url += data.special_category >=0 ? `&special_category=${data.special_category}` : ''

  return axiosIns.get(url)
    .then(response => response.data)
}

 axiosIns.editDeterminationsList = (id, data) => axiosIns.patch(`api/determinations/${id}/`, data)
 .then(response => response.data)

 /*  REPORTS EPS */

axiosIns.getReports = data => {
  let query = '?'

  if (data?.page && data?.page_size) query +=
    query.length >1?
      `&page=${data.page}&page_size=${data.page_size}` : `page=${data.page}&page_size=${data.page_size}`

  if (data?.summary) query +=
    query.length > 1?
     `&summary=${data.summary}` : `summary=${data.summary}`

  if (data?.search) query +=
  query.length > 1?
    `&search=${data.search}` : `search=${data.search}`

  if (data?.status >= 0) query +=
  query.length > 1?
    `&status=${data.status}` : `status=${data.status}`

  return axiosIns.get(`api/reports/${query.length > 1? query : ''}`)
    .then(response => response.data)
}

axiosIns.getReport = (id) => axiosIns.get(`api/reports/${id}/`)
  .then(response => response.data)

axiosIns.updateReport = (id, form) => axiosIns.patch(`api/reports/${id}/`, form)
  .then(response => response)

axiosIns.sendDeterminationPDF = (id, form) => axiosIns.post(`api/reports/${id}/add-det-pdf`, form, {headers: {'Content-Type': 'multipart/form-data'}})
  .then(response => response)

axiosIns.getFilesVisibility = (id) => axiosIns.get(`api/reports/${id}/file_status`)
  .then(response => response.data)

axiosIns.toggleFilesVisibility = (id) => axiosIns.post(`api/reports/${id}/enabled_files`)
  .then(response => response)

axiosIns.sendGeneralAnnexes = (id, form) => axiosIns.post(`api/reports/${id}/general-annexes`, form, {headers: {'Content-Type': 'multipart/form-data'}})
  .then(response => response)

//report id
axiosIns.deleteAnnexesFile = (id, form) => axiosIns.delete(`api/reports/${id}/files`, {data: form})
  .then(response => response)

axiosIns.showReportNotification = (id) => axiosIns.get(`api/reports/${id}/notifications`)
  .then(response => response)

axiosIns.finishReport = (id) => axiosIns.get(`api/reports/${id}/finish`)
  .then(response => response)

axiosIns.createReportPreview = (id) => axiosIns.get(`api/reports/${id}/report_preview`)
  .then(response => response)

axiosIns.checkReportPayment = id => axiosIns.get(`api/requests/${id}/check-payments`)
  .then(response => response)

//check timeout, this request take more time
axiosIns.sendReport = (id, form) => axiosIns.post(`api/reports/${id}/export`, form, {timeout: 10000})
  .then(response => response)


 /*  REQUESTS EPS */
axiosIns.createRequest = form => axiosIns.post(`api/requests/`, form)
 .then(response => response.data)

axiosIns.createRequestSkuCheck = form => axiosIns.post(`api/requests/create-sku-ckeck`, form)
 .then(response => response)

axiosIns.getRequestList = (data) => {
  // let url = `api/requests/?page=${data.page}&page_size=${data.page_size}`

  let url =
    data.page
      ? `api/requests/?page=${data.page}&page_size=${data.page_size}`
      : `api/requests/?paginated=false`

  url += data.search?.length ? `&search=${data.search}` : ''
  url += data.summary ? `&summary=${data.summary}` : ''
  url += data.status >= 0 ? `&status=${data.status}` : ''
  url += data.since?.length ? `&since=${data.since}` : ''
  url += data.to?.length ? `&to=${data.to}` : ''
  url += data.sampling_mode >= 0 ? `&sampling_mode=${data.sampling_mode}` : ''

  return axiosIns.get(url)
    .then(response => response.data)
}

axiosIns.getRequest = (id) => axiosIns.get(`api/requests/${id}/`)
  .then(response => response)

axiosIns.updateRequest = (id, form) => axiosIns.patch(`api/requests/${id}/`, form)
  .then(response => response)

axiosIns.deleteRequest = (id) => axiosIns.delete(`api/requests/${id}/`)
  .then(response => response)



// BEGIN: Invoices EPs
/*  REPORTS EPS */

axiosIns.getInvoices = data => {
  let query = '?'

  if (data?.page && data?.page_size) query +=
    query.length >1?
      `&page=${data.page}&page_size=${data.page_size}` : `page=${data.page}&page_size=${data.page_size}`

  if (data?.summary) query +=
    query.length > 1?
      '&summary=true&paginated=false' : 'summary=true&paginated=false'

  if (data?.search) query +=
    query.length > 1?
      `&search=${data.search}` : `search=${data.search}`

  if (data?.status) query +=
    query.length > 1?
      `&status=${data.status}` : `search=${data.status}`

  if (data?.userdata) query +=
    query.length > 1?
      `&userdata=${data.userdata}` : `search=${data.userdata}`


  return axiosIns.get(`api/invoices/${query.length > 1? query : ''}`)
    .then(response => response.data)
}

axiosIns.createInvoice = form => {
  return axiosIns.post(`api/invoices/`, form)
    .then(response => response.data)
}

//id is id from items
axiosIns.updateInvoiceItems = (form, id) => {

  return axiosIns.patch(`api/items/${id}/`, form)
    .then(response => response.data)
}

axiosIns.getOneInvoice = id => {

  return axiosIns.get(`api/invoices/${id}/`)
    .then(response => response.data)
}

axiosIns.deleteInvoice = id => {

  return axiosIns.delete(`api/invoices/${id}/`)
    .then(response => response.data)
}

// axiosIns.createReceipt = id => {
//   return axiosIns.post(`api/payments/${id}/proof`)
//     .then(response => response.data)
// }

axiosIns.shareReceipt = id => {
  return axiosIns.post(`api/payments/${id}/export`)
    .then(response => response.data)
}

axiosIns.sharePdfElement = (id, form, type) => axiosIns.post(
    `api/${type === 'invoice'? 'invoices' : 'payments' }/${id}/export`,
    form,
    { timeout: 10000 }
  ).then(response => response)
// END: Invoices EPs


// BEGIN: Payment EPs
axiosIns.getPayments = data => {
  let query = '?'

  if (data?.page && data?.page_size) query +=
    query.length >1?
      `&page=${data.page}&page_size=${data.page_size}` : `page=${data.page}&page_size=${data.page_size}`

  if (data?.summary) query +=
    query.length > 1?
      '&summary=true&paginated=false' : 'summary=true&paginated=false'

  if (data?.search) query +=
    query.length > 1?
      `&search=${data.search}` : `search=${data.search}`

  if (data?.userdata) query +=
    query.length > 1?
      `&userdata=${data.userdata}` : `search=${data.userdata}`


  return axiosIns.get(`api/payments/${query.length > 1? query : ''}`)
    .then(response => response.data)
}

axiosIns.createPayment = form => {

  return axiosIns.post(`api/payments/`, form)
    .then(response => response.data)
}

axiosIns.getPaymentFiles = id => axiosIns.get(`api/payments/${id}/files`)
 .then(response => response)

axiosIns.hiddePayment = id => axiosIns.post(`api/hide-payment/${id}`)
 .then(response => response)

// END: Payment EPs

//CURRENT ACCOUNT EPS
axiosIns.getCurrentAccount = (id, data )=> {
  let url = `api/invoices/user-payments?userdata=${id}&page=${data.page}&page_size=${data.page_size}`

  url += data.status >= 0 && data.status != null ? `&status=${data.status}` : ''

  return axiosIns.get(url)
    .then(response => response.data)
}

// DAILY PAYMENTS EPs
axiosIns.getDailyPayments = data => {
  let query = '?'

  if (data?.date_since) query +=
    query.length > 1?
      `&since=${data.date_since}` : `since=${data.date_since}`

  if (data?.date_to) query +=
    query.length > 1?
      `&to=${data.date_to}` : `to=${data.date_to}`

  if (data?.payment_method !== -1) query +=
    query.length > 1?
      `&payment_method=${data.payment_method}` : `search=${data.payment_method}`

  if (data?.page && data?.page_size) query +=
    query.length >1?
      `&page=${data.page}&page_size=${data.page_size}` : `page=${data.page}&page_size=${data.page_size}`

  return axiosIns.get(`api/daily-payments${query.length > 1? query : ''}`)
    .then(response => response.data)
}

Vue.prototype.$api = axiosIns

export default axiosIns

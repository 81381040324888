import Vue from 'vue'
import VueRouter from 'vue-router'
import superadmin_private_url from '../middleware/superadmin-private-url';
import admin_private_url from '../middleware/admin-private-url';
import private_url from '../middleware/private-url';
import public_url from '../middleware/public-url';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      middleware: private_url,
      layout: 'content',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/Profile.vue'),
    meta: {
      middleware: private_url,
      layout: 'content',
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Users.vue'),
    meta: {
      middleware: admin_private_url,
      layout: 'content',
    },
  },
  {
    path: '/veterinary-clinic',
    name: 'veterinary-clinic',
    component: () => import('@/views/VeterinaryClinic.vue'),
    meta: {
      middleware: admin_private_url,
      layout: 'content',
    },
  },
  {
    path: '/tutors',
    name: 'tutors',
    component: () => import('@/views/Tutors.vue'),
    meta: {
      middleware: admin_private_url,
      layout: 'content',
    },
  },
  {
    path: '/patients',
    name: 'patients',
    component: () => import('@/views/Patients.vue'),
    meta: {
      middleware: admin_private_url,
      layout: 'content',
    },
  },
  {
    path: '/determinations',
    name: 'determinations',
    component: () => import('@/views/Determinations.vue'),
    meta: {
      middleware: admin_private_url,
      layout: 'content',
    },
  },
  {
    path: '/lab-request',
    name: 'lab-request',
    component: () => import('@/views/LabRequest.vue'),
    meta: {
      middleware: private_url,
      layout: 'content',
    },
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/Reports.vue'),
    meta: {
      middleware: admin_private_url,
      layout: 'content',
    },
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('@/views/Invoices.vue'),
    meta: {
      middleware: admin_private_url,
      layout: 'content',
    },
  },
  {
    path: '/payments',
    name: 'payments',
    component: () => import('@/views/Payments.vue'),
    meta: {
      middleware: admin_private_url,
      layout: 'content',
    },
  },
  {
    path: '/daily-payments',
    name: 'daily-payments',
    component: () => import('@/views/DailyPayments.vue'),
    meta: {
      middleware: superadmin_private_url,
      layout: 'content',
    },
  },
  {
    path: '/current',
    name: 'current-account',
    component: () => import('@/views/CurrentAccount.vue'),
    meta: {
      middleware: superadmin_private_url,
      layout: 'content',
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/Register.vue'),
    meta: {
      middleware: public_url,
      layout: 'blank',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      middleware: public_url,
      layout: 'blank',
    },
  },
  {
    path: '/account/recover',
    name: 'auth-reset',
    component: () => import('@/views/resetPassword.vue'),
    meta: {
      middleware: public_url,
      layout: 'blank',
    },
  },
  {
    path: '/simulator',
    name: 'simulator',
    component: () => import('@/views/Simulator.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}
  
router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router

import { 
  mdiFileOutline, 
  mdiHomeOutline,
  mdiAccountMultipleOutline,
  mdiReceipt,
  mdiHospitalBoxOutline,
  mdiAccountHeartOutline,
  mdiPaw,
  mdiCurrencyUsd,
  mdiMicroscope,
  mdiContentPaste,
  mdiCreditCardCheck,
  mdiAccountCashOutline,
} from '@mdi/js'

const admins = [
  { subheader: 'GENERAL' },
  { title: 'Home', icon: mdiHomeOutline, to: 'home' },
  { title: 'Solicitudes', icon: mdiFileOutline, to: 'lab-request' },
  { title: 'Determinaciones', icon: mdiMicroscope, to: 'determinations' },
  { title: 'Informes', icon: mdiContentPaste, to: 'reports' },
  { subheader: 'REGISTROS' },
  { title: 'Usuarios', icon: mdiAccountMultipleOutline, to: 'users' },
  { title: 'Veterinarias', icon: mdiHospitalBoxOutline, to: 'veterinary-clinic' },
  { title: 'Tutores', icon: mdiAccountHeartOutline, to: 'tutors' },
  { title: 'Pacientes', icon: mdiPaw, to: 'patients' },
  
  { subheader: 'PAGOS' },
  { title: 'Remitos', icon: mdiReceipt, to: 'invoices' },
  { title: 'Pagos', icon: mdiCurrencyUsd, to: 'payments' },
]

const otherUsers = [
  { subheader: 'USUARIO' },
  { title: 'Home', icon: mdiHomeOutline, to: 'home' },
  { title: 'Solicitudes', icon: mdiFileOutline, to: 'lab-request' },
]

export default {
  otherUsers: otherUsers,
  admin: admins,
  superAdmin: admins.concat([
    { title: 'Cuenta Corriente', icon: mdiCreditCardCheck, to: 'current-account' },
    { title: 'Caja diaria', icon: mdiAccountCashOutline , to: 'daily-payments' },
  ])
}